import React from 'react'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'
import qs from 'qs'
import { setReferrer } from '../utils/referrer'
import { setPartner } from '../utils/partner'

export default ({ data, pageContext: { slug }, location: { search } }) => {
  setReferrer({
    partner: slug,
    ...qs.parse(search, { ignoreQueryPrefix: true })
  })
  setPartner(data.datoCmsPartner)

  const landingUrl = data.datoCmsPartner.landingUrl
    ? data.datoCmsPartner.landingUrl
    : 'https://sharecover.com/'

  const [url, path] = landingUrl.match(/https?:\/\/.*?(\/.*)/)

  // redirect locally
  if (url.match(/sharecover\.[a-z]{2,3}/)) {
    return <Redirect to={path} noThrow />
  }

  // redirect to external url
  const windowGlobal = typeof window !== 'undefined' && window

  if (windowGlobal) {
    windowGlobal.location.href = url
  }

  return null
}

export const query = graphql`
  query PartnerQuery($slug: String!) {
    datoCmsPartner(slug: { eq: $slug }) {
      name
      logo {
        url
      }
      landingUrl
    }
  }
`
